const styles = {
  container: 'font-nbi-pro',

  head: '',
  body: {
    container: 'border-t border-gray-900 border-t-0.5',
    content:
      'py-8 flex flex-col-reverse gap-y-4 sm:flex-row justify-between items-center text-white text-xs',
  },
};

export default styles;
