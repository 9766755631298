'use client';

import React from 'react';
import { usePathname, useRouter } from 'next/navigation';
import i18n from 'config/i18n';
import { TNextClientComponent } from 'types/next';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/shadcn-ui/Select';
import { styles } from '.';
type TProps = {
  lang: string;
};
const LanguageSection: TNextClientComponent<TProps> = props => {
  const {
    lang
  } = props;
  const pathname = usePathname();
  const router = useRouter();
  const handleGenerateLabelFromLocale = (locale: string) => {
    let result = locale;
    switch (locale) {
      case 'en':
        result = 'EN';
        break;
      case 'nl':
        result = 'NL';
        break;
      case 'fr':
        result = 'FR';
        break;
    }
    return result;
  };
  const languages = i18n.locales.map(locale => ({
    id: locale,
    label: handleGenerateLabelFromLocale(locale),
    href: `/${locale}`
  }));
  return <div className={styles.container} data-sentry-component="LanguageSection" data-sentry-source-file="LanguageSection.tsx">
      <Select onValueChange={value => {
      router.push(`${pathname.replace(`${lang}`, `${value.split('/')[1]}`)}`);
    }} value={`/${lang}`} data-sentry-element="Select" data-sentry-source-file="LanguageSection.tsx">
        <SelectTrigger className="!text-xs border-none ring-none font-sans" aria-label="language-picker" data-sentry-element="SelectTrigger" data-sentry-source-file="LanguageSection.tsx">
          <SelectValue placeholder={lang.toUpperCase()} data-sentry-element="SelectValue" data-sentry-source-file="LanguageSection.tsx" />
        </SelectTrigger>
        <SelectContent data-sentry-element="SelectContent" data-sentry-source-file="LanguageSection.tsx">
          {languages.map(item => <SelectItem key={item.id} value={item.href} className="text-sm font-sans">
              {item.label}
            </SelectItem>)}
        </SelectContent>
      </Select>
    </div>;
};
export default LanguageSection;