import React from 'react';
import Link from 'next/link';
import { TNextClientComponent } from 'types/next';
import { useTranslation } from 'hooks/useTranslation';
import { Button } from 'components/shadcn-ui/Button';
import Container from 'components/tailwind-catalyst/Container';
import { styles } from '.';
type TProps = {
  lang: string;
};
const CtaSection: TNextClientComponent<TProps> = props => {
  const {
    lang
  } = props;
  const {
    t
  } = useTranslation(lang);
  return <div className={styles.container} aria-disabled="true" data-sentry-component="CtaSection" data-sentry-source-file="CtaSection.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="CtaSection.tsx">
        <div className={styles.content}>
          <h3 className={styles.title}>{t('component.footer.title.h3')}</h3>
          <p className={styles.description}>
            {t('component.footer.paragraph.description')}
          </p>
          <Link href={`/${lang}/contact`} data-sentry-element="Link" data-sentry-source-file="CtaSection.tsx">
            <Button className={styles.button} data-sentry-element="Button" data-sentry-source-file="CtaSection.tsx">
              {t('component.footer.label.getInContact')}
            </Button>
          </Link>
        </div>
      </Container>
    </div>;
};
export default CtaSection;