import React from 'react';
import { TNextClientComponent } from 'types/next';
import { useTranslation } from 'hooks/useTranslation';
import Icon from 'components/custom/Icon';
import Container from 'components/tailwind-catalyst/Container';
import { styles } from '.';
type TProps = {
  lang: string;
  socials: {
    __typename: string;
    icon: string;
    url: string;
  }[];
  company: any;
};
const InfoSection: TNextClientComponent<TProps> = props => {
  const {
    lang,
    socials,
    company
  } = props;
  const {
    t
  } = useTranslation(lang);
  return <div className={styles.container} aria-disabled="true" data-sentry-component="InfoSection" data-sentry-source-file="InfoSection.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="InfoSection.tsx">
        <div className="pt-12 pb-6 flex flex-col sm:flex-row gap-y-4 gap-x-8 text-xs text-white font-sans text-center items-center sm:items-start sm:text-left">
          <ul className="flex flex-col sm:items-start gap-y-1 items-center">
            <li className="font-bold">{company.name}</li>
            <li className={styles.item}>
              {company.address.streetName} {company.address.streetNumber}
            </li>
            <li className={styles.item}>
              {company.address.postalCode} {company.address.city}
            </li>
            <li className={styles.item}>{company.address.country}</li>
          </ul>
          <ul className="flex flex-col items-start gap-y-1">
            <li className={styles.item}>
              <Icon accessor={'phone' as any} className="text-xs" data-sentry-element="Icon" data-sentry-source-file="InfoSection.tsx" />
              {company.telephoneNumber}
            </li>
            <li className={styles.item}>
              <Icon accessor={'envelope' as any} className="text-xs" data-sentry-element="Icon" data-sentry-source-file="InfoSection.tsx" />
              {company.emailAddress}
            </li>
          </ul>
        </div>
        <div className={styles.content}>
          <p className={styles.paragraph}>
            {t('label.custom.credits', {
            year: new Date().getFullYear()
          })}
          </p>
          <ul className={styles.list}>
            {socials?.map(social => <li key={social.icon}>
                <a href={social.url} target="_blank" className={styles.icon} aria-label={social.icon}>
                  <Icon type="brands" accessor={social.icon as any} />
                </a>
              </li>)}
          </ul>
        </div>
      </Container>
    </div>;
};
export default InfoSection;