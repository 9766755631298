const styles = {
  container:
    'py-12 sm:py-20 flex justify-center items-center bg-primary-500 font-sans',
  content: 'flex flex-col items-center',

  title:
    'text-2xl text-white font-bold tracking-display pb-2 text-center max-w-sm sm:max-w-md md:max-w-none',
  description: 'px-6 text-white text-sm text-center',

  button: 'mt-8 bg-primary-300 hover:bg-primary-300/80 cursor-pointer',
};

export default styles;
