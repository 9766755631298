const styles = {
  container: 'bg-stone',
  content:
    'pb-10 flex flex-col-reverse gap-y-4 sm:flex-row justify-between items-center text-white text-xs',

  list: 'flex flex-row items-center gap-x-5',
  icon: 'text-white hover:opacity-40 text-base',
  paragraph:
    'max-w-xs sm:max-w-none opacity-40 font-nbi-mono text-center tracking-display text-[0.6rem]',

  item: 'font-thin flex flex-row items-center gap-x-1',
};

export default styles;
