'use client';

import React from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { toClassName } from 'helpers/format';
import { TNextClientComponent } from 'types/next';
import { useTranslation } from 'hooks/useTranslation';
import { NavigationMenu, NavigationMenuItem, NavigationMenuLink, NavigationMenuList, navigationMenuTriggerStyle } from 'components/shadcn-ui/NavigationMenu';
import { styles } from '.';
type TProps = {
  routes: {
    id: string;
    label: string;
    path: string;
  }[];
  //
  lang: string;
};
const NavigationMenuSection: TNextClientComponent<TProps> = props => {
  const {
    routes,
    lang
  } = props;
  const pathname = usePathname();
  const {
    t
  } = useTranslation(lang);
  return <div className={styles.container} data-sentry-component="NavigationMenuSection" data-sentry-source-file="NavigationMenuSection.tsx">
      <NavigationMenu className={styles.primary} data-sentry-element="NavigationMenu" data-sentry-source-file="NavigationMenuSection.tsx">
        <NavigationMenuList data-sentry-element="NavigationMenuList" data-sentry-source-file="NavigationMenuSection.tsx">
          {routes.map(route => <NavigationMenuItem key={route.id}>
              <Link href={route.path} legacyBehavior passHref>
                <NavigationMenuLink className={toClassName(styles.link, pathname.includes(route.path) && '!opacity-20')}>
                  {route.label}
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem>)}
          <NavigationMenuItem data-sentry-element="NavigationMenuItem" data-sentry-source-file="NavigationMenuSection.tsx">
            <Link href={`/${lang}/tiny-houses`} legacyBehavior passHref data-sentry-element="Link" data-sentry-source-file="NavigationMenuSection.tsx">
              <NavigationMenuLink aria-disabled="true" className={toClassName(navigationMenuTriggerStyle(), '!text-sm !font-sans !text-secondary-500', pathname.includes('/tiny-houses') && '!opacity-20')} data-sentry-element="NavigationMenuLink" data-sentry-source-file="NavigationMenuSection.tsx">
                {t('component.navbar.label.tinyHouses')}
              </NavigationMenuLink>
            </Link>
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>
    </div>;
};
export default NavigationMenuSection;