'use client';

import React from 'react';
import { useParams, usePathname } from 'next/navigation';
import { TNextClientComponent } from 'types/next';
import CtaSection from './_components/CtaSection';
import InfoSection from './_components/InfoSection';
import { styles } from '.';
type TProps = {
  socials: {
    __typename: string;
    icon: string;
    url: string;
  }[];
  company: any;
};
const Footer: TNextClientComponent<TProps> = props => {
  const {
    socials,
    company
  } = props;
  const params = useParams();
  const pathname = usePathname();
  return <div className={styles.container} data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      {pathname !== `/${params.lang}/contact` && <CtaSection lang={params?.lang as string} />}
      <InfoSection lang={params?.lang as string} socials={socials} company={company} data-sentry-element="InfoSection" data-sentry-source-file="Footer.tsx" />
    </div>;
};
export default Footer;