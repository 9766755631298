'use client';

import React, { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { toClassName } from 'helpers/format';
import { handleUseStrapiImageUrl } from 'helpers/strapi';
import { APIResponse } from 'types/models';
import { TNextClientComponent } from 'types/next';
import { useTranslation } from 'hooks/useTranslation';
import Icon from 'components/custom/Icon';
import { Button } from 'components/shadcn-ui/Button';
import { NavigationMenu, NavigationMenuItem, NavigationMenuLink, NavigationMenuList } from 'components/shadcn-ui/NavigationMenu';
import { Sheet, SheetContent, SheetTrigger } from 'components/shadcn-ui/Sheet';
import { styles } from '.';
import LanguageSection from '../LanguageSection';
type TProps = {
  logo: APIResponse<'plugin::upload.file'> | null;
  routes: {
    id: string;
    label: string;
    path: string;
  }[];
  socials: {
    __typename: string;
    icon: string;
    url: string;
  }[];
  lang: string;
};
const SideMenuSection: TNextClientComponent<TProps> = props => {
  const {
    logo,
    lang,
    routes,
    socials
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const pathname = usePathname();
  const {
    t
  } = useTranslation(lang);
  return <div className={styles.container} data-sentry-component="SideMenuSection" data-sentry-source-file="SideMenuSection.tsx">
      <Sheet open={isOpen} onOpenChange={setIsOpen} data-sentry-element="Sheet" data-sentry-source-file="SideMenuSection.tsx">
        <SheetTrigger aria-label="hamburger" data-sentry-element="SheetTrigger" data-sentry-source-file="SideMenuSection.tsx">
          <Icon accessor="bars" data-sentry-element="Icon" data-sentry-source-file="SideMenuSection.tsx" />
        </SheetTrigger>
        <SheetContent side="left" className="w-[90vw] !p-0 border-r border-black" data-sentry-element="SheetContent" data-sentry-source-file="SideMenuSection.tsx">
          <div className={styles.sheet.container}>
            <div className={styles.sheet.content}>
              <div className={styles.sheet.head} onClick={() => setIsOpen(false)}>
                <Link href="/" data-sentry-element="Link" data-sentry-source-file="SideMenuSection.tsx">
                  <Image src={handleUseStrapiImageUrl(logo?.data.attributes.url ?? '')} width={170} height={50} alt="logo" className={styles.image} data-sentry-element="Image" data-sentry-source-file="SideMenuSection.tsx" />
                </Link>
              </div>

              <div className={styles.sheet.body}>
                <NavigationMenu className="!items-start !justify-start" data-sentry-element="NavigationMenu" data-sentry-source-file="SideMenuSection.tsx">
                  <NavigationMenuList className={styles.sheet.list} data-sentry-element="NavigationMenuList" data-sentry-source-file="SideMenuSection.tsx">
                    {routes.map(route => <NavigationMenuItem key={route.id} onClick={() => setIsOpen(false)}>
                        <Link href={route.path} legacyBehavior passHref>
                          <NavigationMenuLink className={toClassName('font-sans', pathname.includes(route.path) && 'opacity-20')}>
                            {route.label}
                          </NavigationMenuLink>
                        </Link>
                      </NavigationMenuItem>)}
                    <NavigationMenuItem onClick={() => setIsOpen(false)} data-sentry-element="NavigationMenuItem" data-sentry-source-file="SideMenuSection.tsx">
                      <Link href="/tiny-houses" legacyBehavior passHref data-sentry-element="Link" data-sentry-source-file="SideMenuSection.tsx">
                        <NavigationMenuLink className={toClassName(
                      // navigationMenuTriggerStyle(),
                      '!text-sm !font-sans !text-secondary-500', pathname.includes('/tiny-houses') && '!opacity-20')} data-sentry-element="NavigationMenuLink" data-sentry-source-file="SideMenuSection.tsx">
                          {t('component.navbar.label.tinyHouses')}
                        </NavigationMenuLink>
                      </Link>
                    </NavigationMenuItem>
                  </NavigationMenuList>
                </NavigationMenu>
              </div>

              <div className={styles.sheet.foot}>
                <LanguageSection lang={lang} data-sentry-element="LanguageSection" data-sentry-source-file="SideMenuSection.tsx" />
              </div>
            </div>

            <div className="text-sm font-sans">
              <div className="bg-primary-500 p-8">
                <Link href={`/${lang}/contact`} data-sentry-element="Link" data-sentry-source-file="SideMenuSection.tsx">
                  <Button className={styles.button} onClick={() => setIsOpen(false)} data-sentry-element="Button" data-sentry-source-file="SideMenuSection.tsx">
                    {t('component.footer.label.getInContact')}
                  </Button>
                </Link>
              </div>
              <div className="bg-stone p-8 text-white">
                <ul className={styles.list}>
                  {socials?.map(social => <li key={social.icon}>
                      <a href={social.url} target="_blank"
                  // className={styles.icon}
                  aria-label={social.icon}>
                        <Icon type="brands" accessor={social.icon as any} />
                      </a>
                    </li>)}
                </ul>
              </div>
            </div>
          </div>
        </SheetContent>
      </Sheet>
    </div>;
};
export default SideMenuSection;